import { type ReactNode, type SyntheticEvent, useState } from 'react';

import ReactPlayer from 'react-player';

import useTranslation from '@hooks/useTranslation';
import { Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <>
                        <Typography>{children}</Typography>
                    </>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const IntroductionVideos = ({
    width = '100%',
    height = 'auto',
}: {
    width?: string;
    height?: string;
}) => {
    const [value, setValue] = useState(0);
    const { t } = useTranslation('library');

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label={t('intro_video_title')} {...a11yProps(0)} />
                    {/* <Tab label={t('userguide_video_title')} {...a11yProps(1)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ReactPlayer
                    url="https://firebasestorage.googleapis.com/v0/b/firebase-prj-prod-hadith.appspot.com/o/المنصة%20الحديثية.mp4?alt=media&token=a5dafaa1-d221-4118-8bb6-9d5c8722babb"
                    width={width}
                    height={height}
                    style={{
                        margin: '0 auto',
                    }}
                    playing={false}
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload',
                            },
                        },
                    }}
                />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                <ReactPlayer
                    url="https://firebasestorage.googleapis.com/v0/b/firebase-prj-dev-hadith.appspot.com/o/videos%2Fuserguide.mp4?alt=media&token=572556ba-430b-4772-a443-7b1cd681ade5"
                    width="100%"
                    height="auto"
                    playing={false}
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload',
                            },
                        },
                    }}
                />
            </TabPanel> */}
        </>
    );
};

export default IntroductionVideos;
