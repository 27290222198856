import { useState } from 'react';

import { useRouter } from 'next/router';

import MobileBgSvg from '@components/MobileBgSvg';
import { SeoHead } from '@components/SeoHead';
import SearchBase from '@components/base/SearchBase';
import { IntroductionModal } from '@components/modal/IntroductionModal/IntroductionModal';
import { MainSearch } from '@components/views';

import { narrationsIndex } from '@config/reactive-search';
import useTranslation from '@hooks/useTranslation';
import { PageLayout } from '@layout';
import { Search as SearchIcon } from '@mui/icons-material';
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { GridFilterListIcon } from '@mui/x-data-grid';

import { colors } from 'theme';

const SearchPage = () => {
    const router = useRouter();
    const [collapsed, setCollapsed] = useState(false);
    const [showFiltersMobileMainSearch, setShowFiltersMobileMainSearch] =
        useState(false);
    const [selectedFiltersPoint, setSelectedFiltersPoint] = useState(false);
    const [
        openMobileSearchModalFromHeader,
        setOpenMobileSearchModalFromHeader,
    ] = useState(false);

    const textSearchFromUrl = (
        (router.query['search'] as string) ?? ''
    ).replaceAll('"', '');
    const [textSearch, setTextSearch] = useState(textSearchFromUrl);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation('library');

    const searchField = (
        <TextField
            variant="outlined"
            placeholder={t('search_in_hadiths_short')}
            onClick={(e) => {
                e.preventDefault();
                setOpenMobileSearchModalFromHeader(true);
            }}
            onFocus={(e) => {
                e.currentTarget.blur();
            }}
            size="small"
            sx={{
                borderRadius: '4px',
                flexGrow: 1,
                mr: '10px',
                outline: 'none',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: '1px solid rgba(0,0,0,0.2)',
                    },
                    '&.Mui-focused fieldset': {
                        border: '1px solid rgba(0,0,0,0.2)',
                    },
                },
            }}
            InputProps={{
                style: {
                    textAlign: 'left',
                    paddingLeft: 0,
                },

                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="search">
                            <SearchIcon
                                fontSize="small"
                                color="secondary"
                                aria-label="search"
                            />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );

    const headerIcons = (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {searchField}
            <IconButton
                onClick={() => {
                    setShowFiltersMobileMainSearch(true);
                }}
                sx={{
                    backgroundColor: '#F7F7F7',
                    borderRadius: '5px',
                }}
                size="small"
            >
                <GridFilterListIcon color="secondary" />
                {selectedFiltersPoint && (
                    <span
                        style={{
                            content: '""',
                            position: 'absolute',
                            top: '25%',
                            right: '20%',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: colors.tertiary, // Customize the point color
                        }}
                    ></span>
                )}
            </IconButton>
        </Box>
    );

    // const showLanding =
    //     isMobile &&
    //     !(
    //         textSearch ||
    //         openMobileSearchModalFromHeader ||
    //         showFiltersMobileMainSearch ||
    //         selectedFiltersPoint
    //     );
    const showLanding = false;

    return (
        <>
            <SeoHead />
            <PageLayout
                headerIcons={headerIcons}
                hasSelectedFilters={selectedFiltersPoint}
            >
                <SearchBase
                    app={narrationsIndex}
                    // NOTE: Very important to use this prop to call getServerSideProps.  Don't use getSearchParams props as it will not read filters from the search query
                    setSearchParams={(newURL) => {
                        router.push(newURL, undefined, { shallow: true });
                    }}
                >
                    {showLanding ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'start',
                            }}
                        >
                            <MobileBgSvg
                                width={window.innerWidth}
                                height={window.innerHeight - 180}
                            />
                        </Box>
                    ) : null}
                    <Box
                        sx={{
                            display: showLanding ? 'none' : 'block',
                        }}
                    >
                        <MainSearch
                            setSelectedFiltersPoint={setSelectedFiltersPoint}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                            showFiltersMobileMainSearch={
                                showFiltersMobileMainSearch
                            }
                            setShowFiltersMobileMainSearch={
                                setShowFiltersMobileMainSearch
                            }
                            openMobileSearchModalFromHeader={
                                openMobileSearchModalFromHeader
                            }
                            setOpenMobileSearchModalFromHeader={
                                setOpenMobileSearchModalFromHeader
                            }
                            textSearch={textSearch}
                            setTextSearch={setTextSearch}
                        />
                    </Box>
                </SearchBase>
                <IntroductionModal />
            </PageLayout>
        </>
    );
};

export default SearchPage;
