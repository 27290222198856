import { useState } from 'react';

import { IntroductionVideos } from '@components/introduction';

import useTranslation from '@hooks/useTranslation';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

export const IntroductionModal = () => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation('library');

    const handleClose = () => {
        localStorage.setItem('isUserFirstTime', 'true');
        setOpen(false);
    };

    return (
        <Dialog
            dir="rtl"
            open={open}
            fullWidth={true}
            onClose={() => {
                handleClose();
            }}
            scroll="body"
        >
            <DialogTitle display="flex" justifyContent="space-between">
                {t('video_welcome_text')}
                <IconButton
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <CancelIcon
                        sx={{
                            color: '#B7A4A4',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        fontSize="large"
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <IntroductionVideos />
            </DialogContent>
        </Dialog>
    );
};
